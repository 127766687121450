import { graphql } from 'gatsby'
import React from 'react'

import { Helmet } from 'react-helmet'

import Meta from 'components/meta'
import Layout from 'components/layout'

import { useJsonForm } from 'gatsby-tinacms-json'

let lang = 'de'

const EnFeedbackIndex = ({ location, data }) => {
  const [node] = useJsonForm(data.dataJson, FeedbackPageForm)
  const page = node.pages.feedback.de

  return (
    <Layout location={location}>
      <Helmet>
        <title>{page.site_title} - Reinhold Rößler</title>
      </Helmet>
      <div
        className="feedback-body"
        style={{ marginTop: `96px`, backgroundColor: `#ece4df` }}
      >
        <section>
          <div className="container">
            <h1>{page.title}</h1>
            {page.testimonials.map(item => (
              <div className="testimonial">
                <p className="testimonial-text">{item.body}</p>
                <p className="testimonial-author float-right">{item.author}</p>
              </div>
            ))}
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default EnFeedbackIndex

const FeedbackPageForm = {
  fields: [
    {
      name: `rawJson.pages.feedback.${lang}.site_title`,
      component: 'text',
      label: 'Site Title',
      required: true,
    },
    {
      name: `rawJson.pages.feedback.${lang}.title`,
      component: 'text',
      label: 'Page Title',
      required: true,
    },
    {
      component: 'group-list',
      name: `rawJson.pages.feedback.${lang}.testimonials`,
      label: 'Testimonials',
      required: true,
      fields: [
        {
          label: 'Author',
          name: 'author',
          component: 'text',
          required: true,
        },
        {
          label: 'Text',
          name: 'body',
          component: 'text',
          required: true,
        },
      ],
      itemProps: item => ({
        label: item.author ? item.author : 'Testimonial',
        key: item,
      }),
    },
  ],
}

export const pageQuery = graphql`
  query {
    dataJson {
      pages {
        feedback {
          de {
            site_title
            title
            testimonials {
              author
              body
            }
          }
        }
      }
      rawJson
      fileRelativePath
    }
  }
`
